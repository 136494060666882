<template>
  <v-container class="pa-0 pb-5">
    <loader v-if="isLoading" :message="'Getting access tokens ...'" />

    <section class="overflow-hidden" v-else>
      <empty-state
        v-if="tokens.length === 0"
        message="No Access token created yet."
        actionBtnText="Add new Access token"
        :action="() => $router.push({ name: 'createtoken' })"
      >
        <div class="text-center">
          <img :src="require(`@/assets/svgs/empty-blocked-domains.svg`)" />
        </div>
      </empty-state>

      <section v-else>
        <v-row
          class="pa-1 ma-0 mb-3"
          style="background: #e9e9e9; border-radius: 8px"
        >
          <v-col class="d-flex flex-column py-0" cols="6">
            <s-text weight="medium" color="gray" size="sm"> Token name </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0 pl-0" cols="5">
            <s-text weight="medium" color="gray" size="sm"> Created </s-text>
          </v-col>
        </v-row>

        <list-card
          v-for="token in tokens"
          :key="token.id"
          :name="token.name"
          :value="token.created_at"
          icon="vpn_key"
        >
          <v-btn depressed icon @click="deleteToken(token)">
            <s-icon name="trash" />
          </v-btn>
        </list-card>
      </section>
    </section>

    <!-- create token -->
    <s-dialog v-model="showModal" persistent isModal title="Create New Token">
      <create-token v-if="showModal" @refresh="getTokens()" />
    </s-dialog>

    <!-- delete token -->
    <s-dialog
      v-model="showDeleteModal"
      persistent
      max-width="400"
      title="Delete Token"
      :close-default="false"
      @close="closeDeleteModal()"
    >
      <delete-token
        v-if="showDeleteModal"
        :token="currentToken"
        @close="closeDeleteModal()"
      />
    </s-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ListCard from '@/components/cards/ListCard'
import Loader from '@/components/cards/Loader'
import EmptyState from '@/components/cards/EmptyState'
import CreateToken from '@/views/settings/developer/CreateToken'
import DeleteToken from '@/views/settings/developer/DeleteToken'
export default {
  name: 'Tokens',
  components: {
    ListCard,
    Loader,
    EmptyState,
    'create-token': CreateToken,
    'delete-token': DeleteToken,
  },
  computed: {
    ...mapGetters({
      tokens: 'admin/tokens',
    }),
  },
  data() {
    return {
      showModal: this.$route.meta.showModal,
      showDeleteModal: false,
      isLoading: true,
      currentToken: null,
      isWorking: false,
    }
  },
  methods: {
    goToCreate() {
      this.$router.push({ name: 'createtoken' })
    },
    getTokens() {
      this.$store.dispatch('admin/getTokens').then(() => {
        this.isLoading = false
      })
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.currentToken = null
    },
    deleteToken(token) {
      if (this.showDeleteModal) return

      this.currentToken = token
      this.showDeleteModal = true
    },
  },
  created() {
    this.getTokens()
  },
  watch: {
    '$route.meta'({ showModal }) {
      this.showModal = showModal
    },
  },
}
</script>
