<template>
  <div
    class="fill-height d-flex loader-height flex-column align-center justify-center"
  >
    <div><slot></slot></div>
    <span class="mb-4">
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </span>
    <s-text weight="medium" color="dark">{{ message }}</s-text>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.loader-height {
  height: 400px;
}
</style>
