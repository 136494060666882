<template>
  <section>
    <v-row class="app-card app-card-content my-3 mx-0 list">
      <v-col cols="6" class="bucket-name-holder d-flex align-center ma-0">
        <v-icon v-if="showIcon" class="mr-4" :color="iconColor">{{
          icon
        }}</v-icon>
        <s-text weight="medium"> {{ name }}</s-text>
      </v-col>
      <v-col cols="5" class="pl-0 d-flex align-center ma-0">
        <s-text weight="normal">{{ value }}</s-text>
      </v-col>
      <v-col
        cols="1"
        class="pa-0 pr-4 d-flex align-center justify-end ma-0 action_group"
      >
        <slot />
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: 'ListCard',
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: '#EC3944',
    },
    icon: {
      type: String,
      default: 'dns',
    },
    name: {
      type: String,
      require: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isWorking: false,
    }
  },
}
</script>
