<template>
  <v-container class="pa-0">
    <loader v-if="isLoading && isEdit" message="Loading..." />
    <section v-if="(!isLoading || !isEdit) && !showModal">
      <v-form ref="form" @submit.prevent="submit" v-model="valid" novalidate>
        <v-row class="form pt-5 pl-6 pr-6 pb-0 justify-space-between">
          <v-col>
            <s-text-field
              label="Name"
              name="name"
              filled
              required
              :rules="[rules.required]"
              v-model="value.name"
            />
            <s-text-field
              label="Description"
              name="description"
              filled
              required
              :rules="[rules.required]"
              v-model="value.description"
            />
            <s-btn
              small
              type="submit"
              color="primary"
              elevation="0"
              :disabled="!valid"
              :style="{ width: '150px' }"
              :loading="isWorking"
            >
              {{ isEdit ? 'Update' : 'Submit' }}
            </s-btn>
          </v-col>
        </v-row>
      </v-form>
    </section>

    <div class="pt-5 px-6 pb-8" v-if="showModal">
      <v-alert dense outlined type="error" class="alert-banner">
        Make sure to copy your new access token. You won’t have access to this
        again.
      </v-alert>

      <div class="scroll">
        <s-text weight="medium" size="sm">Token</s-text>
        <div class="token-panel pa-5 mb-5" v-if="generatedToken">
          {{ generatedToken.token }}
          <v-btn
            depressed
            icon
            class="back-btn"
            @click="copyToClipboard(generatedToken.token)"
          >
            <v-icon color="black" size="16">mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <s-btn elevation="0" color="primary" small @click="goBack()">
          Close
        </s-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'

export default {
  name: 'CreateEditToken',
  components: { Loader },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    tokenID: {
      type: String,
    },
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      isWorking: false,
      showModal: false,
      value: {
        name: '',
        description: '',
      },
      showPasswordText: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      generatedToken: {},
    }
  },
  computed: {
    ...mapGetters({
      token: 'admin/currentToken',
    }),
  },
  methods: {
    isValueChanged() {
      return (
        JSON.stringify(this.value) !==
        JSON.stringify({
          name: this.token.name,
          description: this.token.description,
        })
      )
    },
    submit() {
      if (this.isEdit && !this.isValueChanged()) return

      this.isWorking = true

      this.$store
        .dispatch(
          `admin/${this.$props.isEdit ? 'editToken' : 'createToken'}`,
          this.isEdit
            ? {
              id: this.tokenID,
              ...this.value,
            }
            : this.value
        )
        .then((data) => {
          if (this.isEdit) {
            this.$router.push({ name: 'tokens' })
            return
          }

          this.generatedToken = {
            token: data.token,
            refreshToken: data.refresh_token,
          }

          this.showModal = true
          this.$emit('refresh')
        })
        .finally(() => (this.isWorking = false))
    },
    setData() {
      this.value = {
        name: this.token.name,
        description: this.token.description,
        fqdn: this.token.fqdn,
        jwt: this.token.jwt,
      }
    },
    goBack() {
      if (this.showModal) this.showModal = false

      this.$router.push(
        this.isEdit
          ? { name: 'viewtoken', params: { id: this.token.id } }
          : { name: 'tokens' }
      )
    },
    copyToClipboard: function (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$store.dispatch('ui/showSuccess', {
          title: 'Copied to clipboard!',
          description: 'Token was successfully copied to your clipboard.',
        })
      })
    },
  },
  created() {
    if (this.$props.isEdit) {
      this.isLoading = true

      this.$store
        .dispatch('admin/getToken', this.$props.tokenID)
        .then(() => this.setData())
        .finally(() => (this.isLoading = false))
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-left: 30px;
  padding-right: 30px;
}

.page-header {
  display: flex;
  align-items: center;

  margin-top: 15px;

  .back-arrow {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    padding: 7px;
    background: #f9f9f9;
    border-radius: 50%;

    &:hover {
      background: #f0f0f0;
    }
  }
}

.token-panel {
  background: var(--v-grayBg-base);
  border-radius: 10px;
  border: 1px solid var(--v-border-base);
  cursor: pointer;
  font-family: monospace;
}

.alert-banner {
  border-radius: 10px;
}

.scroll {
  overflow: hidden scroll;
  height: 60vh;
}
</style>
