<template>
  <section class="empty_state d-flex flex-column align-center justify-center">
    <div>
      <slot></slot>
    </div>
    <div class="text-center">
      <div class="empty_state__message__container">
        <s-text
          v-if="hint"
          weight="medium"
          size="lg"
          color="black"
          class="ma-2 text__hint text-center"
        >
          {{ hint }}
        </s-text>
        <s-text
          v-if="message"
          weight="medium"
          size="md"
          color="grayTextAlt"
          class="ma-2 text__message"
        >
          {{ message }}
        </s-text>
      </div>

      <s-btn
        type="button"
        :color="actionBtnColor"
        class="ma-3"
        elevation="0"
        v-if="action"
        @click="action"
        small
      >
        {{ actionBtnText }}
      </s-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    message: {
      type: String,
    },
    hint: {
      type: String,
      required: false,
    },
    action: {
      type: Function,
    },
    actionBtnText: {
      type: String,
      default: 'Refresh',
    },
    actionBtnColor: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.empty_state {
  height: 400px;
}

.empty_state__message__container {
  max-width: 319px !important;
  width: 319px !important;
}

.text__hint {
  @media #{$smalldown} {
    font-size: 18px !important;
    max-width: 200px !important;
  }
}
</style>
