<template>
  <create-edit-token @refresh="$emit('refresh')" />
</template>

<script>
import CreateEditToken from '@/views/settings/developer/CreateEditToken.vue'

export default {
  name: 'CreateToken',
  components: {
    'create-edit-token': CreateEditToken,
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-left: 30px;
  padding-right: 30px;
}

.v-alert {
  margin-bottom: 0;
}
</style>
