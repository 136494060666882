<template>
  <div class="pa-8 pt-5 pb-5">
    <s-text color="grayTextAlt" weight="medium" class="mb-6">
      {{ content.deleteMessage }}
    </s-text>
    <div class="pt-8 pa-0 d-flex align-center">
      <v-spacer></v-spacer>
      <s-btn
        class="mr-2"
        small
        elevation="0"
        @click="close()"
        :disabled="isWorking"
      >
        Cancel
      </s-btn>
      <s-btn
        elevation="0"
        color="primary"
        small
        @click="deleteToken()"
        :disabled="isWorking"
        :loading="isWorking"
      >
        Delete
      </s-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteToken',
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  computed: {
    content: function () {
      return {
        deleteMessage: `Are you sure you want to delete token with name "${
          this.token?.name ?? ''
        }"?`,
      }
    },
  },
  data() {
    return {
      isWorking: false,
    }
  },
  methods: {
    deleteToken() {
      if (this.isWorking) return
      this.isWorking = true

      this.$store.dispatch('admin/deleteToken', this.token.id).then(() => {
        this.isWorking = false
        this.close()
      })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
